export const LocalStorageKeys = {
    circleHistory: 'circleHistory',
    localCounter: 'localCounter',
    colorMode: 'colorMode',
}

export const PUBLIC_CIRCLE_IDS = [
    'cyfU3Z',
    'MvC1qu',
    'cd1tpt',
    'AsoPQt',
    'JsimGt',
    '7TyTJj',
    'ZXvRyi',
    'Xf6R9m',
    'Cq27uo',
]
