import splitbee from '@splitbee/web'
import cx from 'classnames'
import { Share2 as ShareIcon } from 'feather-icons-react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { SplitbeeEvents } from '../helper'
import { Sidebar } from './Sidebar'

const Header = ({
                    shareTitle = 'Appeleven uygulaması ile hızlıca ortak dua grupları oluşturabilir ve hedeflerinizi beraberce okuyup takip edebilirsiniz.',
                    onColorSchemeChange,
                }) => {
    const showShare = 'share' in window.navigator

    const { t, i18n } = useTranslation()


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const lng = urlParams.get('lng')
        if (lng) {
            i18n.changeLanguage(lng)
        }
    }, [])

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng)
        splitbee.track(SplitbeeEvents.changeLanguage, { lng })
    }

    shareTitle = t('shareTitle')

    const share = () => {
        try {
            splitbee.track(SplitbeeEvents.headerShareIconClick, {
                url: window.location.href,
            })
            navigator.share({
                title: shareTitle,
                url: window.location.href,
            })
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <header className="flex items-center justify-between mb-5">
                <div className="flex items-center flex-1">
                    <Sidebar onColorSchemeChange={onColorSchemeChange} />

                    <Link
                        to="/"
                        className="text-gray-600 dark:text-gray-300 flex items-center text-sm"
                    >
                        <img
                            src="/logo192.png"
                            alt="logo"
                            className="w-[28px]"
                        />
                        <span className="ml-2">{t('home')}</span>
                    </Link>
                </div>

                {showShare && (
                    <button
                        className="mr-1 text-gray-900 dark:text-white"
                        onClick={share}
                    >
                        <ShareIcon size={18} />
                    </button>
                )}

                <div className="p-1 dark:bg-neutral-800 bg-white rounded shadow md:mr-2 mr-1 flex">
                    <button
                        onClick={() => changeLanguage('tr')}
                        className={cx(
                            'flex-1 h-6 w-8 flex items-center justify-center dark:text-gray-200 rounded mr-1',
                            {
                                ' dark:bg-neutral-700 bg-gray-200':
                                    i18n.language === 'tr',
                            }
                        )}
                    >
                        <span className="text-xl">🇹🇷</span>
                    </button>
                    <button
                        onClick={() => changeLanguage('en')}
                        className={cx(
                            'flex-1 h-6 w-8 flex items-center justify-center dark:text-gray-200 rounded',
                            {
                                ' dark:bg-neutral-700 bg-gray-200':
                                    i18n.language === 'en',
                            }
                        )}
                    >
                        <span className="text-xl">🇺🇸</span>
                    </button>
                </div>

                {/* <Link
                    to={'/deprem'}
                    className="p-2 flex whitespace-nowrap items-center justify-center rounded text-white text-xs font-bold active:bg-red-700 bg-red-600 mr-1 shadow-red-500 shadow-sm"
                >
                    {t('earthquakeCircles')}
                </Link> */}
                <Link
                    to={'/create-circle'}
                    className="p-2 flex items-center justify-center rounded text-white text-xs font-bold active:bg-herkul-600 bg-herkul-500 shadow"
                >
                    {t('create')}
                </Link>
            </header>
        </>
    )
}

export default Header
