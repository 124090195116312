import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
    incrementCountApi,
    readActiveReaderCount,
    readCircleApi,
    trackUser,
    updateCircleApi,
} from '../api'
import { appLocalStorage, LocalStorageKeys } from '../service/localStorage'
import { Helmet } from 'react-helmet'
import { setCookie, getCookie, formatNumber, SplitbeeEvents } from '../helper'
import { Counter } from '../components/Counter'
import sanitizeHtml from 'sanitize-html'
import { useTranslation } from 'react-i18next'
import splitbee from '@splitbee/web'
import { ArrowLeft } from 'feather-icons-react'
import Loader from '../components/Loader'

const sanitize = (str = '') => {
    return sanitizeHtml(str, {
        allowedTags: ['b', 'i', 'em', 'strong', 'a', 'br', 'span'],
        allowedAttributes: {
            a: ['href'],
        },
    })
}

const CircleDetail = () => {
    const { id } = useParams()
    const [circle, setCircle] = useState({})
    const [localCounter, setLocalCounter] = useState(0)
    const [tab, setTab] = useState(0)
    const [hideBackButton, setHideBackButton] = useState(false)

    const { t, i18n } = useTranslation()

    const [activeReaderCounter, setActiveReaderCounter] = useState(1)
    const [startTime, setStartTime] = useState(0)
    const [hasStarted, setHasStarted] = useState(false)
    const [numOfTimesExceed, setNumOfTimesExceed] = useState(0)

    useEffect(() => {
        readCircleApi(id, (data) => {
            setCircle(data)
        })

        readActiveReaderCount(id, (data) => {
            setActiveReaderCounter(data)
        })

        let user = getCookie('tracking')
        if (user !== '') {
            trackUser(user, id)
        } else {
            user = Date.now().toString(16)
            if (user !== '' && user != null) {
                setCookie('tracking', user, 365)
                trackUser(user, id)
            }
        }
    }, [])

    useEffect(() => {
        if (circle?.id) {
            handleCircleMemo()
        }
    }, [circle?.id])

    useEffect(() => {
        const { search } = window.location
        const params = new URLSearchParams(search)
        const hideBackButton = params.get('hideBackButton')
        setHideBackButton(hideBackButton === 'true')
    }, [])

    // useEffect(() => {
    //     if (circle.counter >= circle.goal && circle.isLoop) {
    //         //:TODO @hamit we can do some of sort animation here when we complete the circle
    //         handleUpdateCircle()
    //     }
    // }, [circle?.counter])

    const onTabChange = (_tab) => {
        setTab(_tab)
        splitbee.track(SplitbeeEvents.circleDetailTabChange, {
            tab: _tab === 0 ? 'Original (Arabic)' : 'Translation',
        })
    }

    // const handleUpdateCircle = async () => {
    //     readCircleApi(id, async (data) => {
    //         const newNumOfCompleted = Math.round(circle.counter / circle.goal)
    //         const newCounter = circle.counter % circle.goal
    //         let payload = {
    //             ...circle,
    //             counter: newCounter,
    //             numOfCompleted: numOfCompleted + newNumOfCompleted,
    //         }
    //         try {
    //             await updateCircleApi(payload)
    //         } catch (e) {
    //             console.log({ e })
    //         }
    //     })
    // }

    const handleCircleMemo = () => {
        const circleHistory = appLocalStorage.get(
            LocalStorageKeys.circleHistory
        )
        if (!circleHistory.includes(circle.id)) {
            circleHistory.push(circle.id)
            appLocalStorage.set(LocalStorageKeys.circleHistory, circleHistory)
        }
        const localCounterHistory = appLocalStorage.get(
            LocalStorageKeys.localCounter
        )
        if (localCounterHistory && localCounterHistory[id] !== undefined) {
            setLocalCounter(localCounterHistory[id])
        }
    }

    const onClearCounter = () => {
        const confirm = window.confirm(
            'Sayacınızı sıfırlamak istediğinizden emin misiniz?'
        )
        if (!confirm) {
            return
        }

        const localCounterHistory =
            appLocalStorage.get(LocalStorageKeys.localCounter) || {}
        if (localCounterHistory) {
            localCounterHistory[id] = 0
        }
        appLocalStorage.set(LocalStorageKeys.localCounter, localCounterHistory)
        setLocalCounter(0)
    }

    const incrementCount = async (e, count = 1) => {
        setStartTime(Date.now())
        setHasStarted(true)
        if (hasStarted) {
            const endTime = Date.now()
            const difference = endTime - startTime
            if (difference < 350) {
                setNumOfTimesExceed(numOfTimesExceed + 1)
            } else {
                setNumOfTimesExceed(0)
            }
            setStartTime(endTime)
        }
        if (numOfTimesExceed > 5) {
            alert('Lütfen zikri okuduğunuzdan emin olun')
            setNumOfTimesExceed(0)
            return
        }

        const newLocalCounter = localCounter + count
        const localCounterHistory =
            appLocalStorage.get(LocalStorageKeys.localCounter) || {}
        if (localCounterHistory) {
            localCounterHistory[id] = newLocalCounter
        }
        appLocalStorage.set(LocalStorageKeys.localCounter, localCounterHistory)

        setLocalCounter(newLocalCounter)
        try {
            e.stopPropagation()
            incrementCountApi(id, count)
        } catch (e) {
            console.log({ e })
        }
    }

    const totalCounter =
        circle?.numOfCompleted && circle.numOfCompleted > 0
            ? circle.numOfCompleted * circle.goal + circle.counter
            : circle.counter
    const numOfCompleted = Math.floor(totalCounter / circle.goal)
    const counter = Math.ceil(totalCounter % circle.goal)
    const percentage = (100 * counter) / circle?.goal
    const progressBar = Math.min((100 * counter) / circle?.goal, 100)

    if (circle && !circle?.id) {
        return <Loader />
    }

    if (!circle) {
        return (
            <h3 className="text-slate-700 dark:text-gray-200">
                Halka bulunamadı. <br />{' '}
                <Link className="mt-2 inline-block" to="/">
                    Anasayfa
                </Link>
            </h3>
        )
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>App Eleven | {circle.title}</title>
                <meta
                    name="title"
                    content={circle.title}
                    data-react-helmet="true"
                />
                <meta
                    name="og:title"
                    content={circle.title}
                    data-react-helmet="true"
                />
                <meta
                    name="description"
                    content={circle.description}
                    data-react-helmet="true"
                />
                <meta
                    name="og:description"
                    content={circle.description}
                    data-react-helmet="true"
                />
            </Helmet>

            <h1 className="text-2xl font-black mb-3 dark:text-gray-200 flex items-center">
                {!hideBackButton && (
                    <Link
                        to="/mobile-app?lng=tr"
                        className="w-10 h-10 flex items-center justify-center -ml-3"
                    >
                        <ArrowLeft />
                    </Link>
                )}
                <span className="flex-1">
                    {i18n.language === 'tr'
                        ? sanitize(circle.title)
                        : sanitize(
                              circle.enTitle ? circle.enTitle : circle.title
                          )}
                    {numOfCompleted > 0 && (
                        <small className="ml-1">
                            ({numOfCompleted + 1}. Halka)
                        </small>
                    )}
                </span>
            </h1>

            {circle?.personCounter && (
                <h4 className="text-gray-700 dark:text-gray-200 flex items-center text-sm mb-2">
                    <span
                        className="w-2 h-2 bg-blue-500 inline-block rounded-full mr-1"
                        aria-hidden="true"
                    />
                    <b className="mx-1 inline-block">{circle.personCounter}+</b>{' '}
                    {t('countAttended')}
                </h4>
            )}

            <h1 className="font-bold dark:text-gray-100 flex items-center">
                <span
                    className="w-4 h-4 bg-herkul-700 dark:bg-herkul-400 inline-block rounded-full mr-2 animate-pulse"
                    aria-hidden="true"
                />
                {activeReaderCounter} {t('countOnline')}
            </h1>
            <div className="pb-[170px]">
                {numOfCompleted > 0 && (
                    <>
                        <h1 className="text-base dark:text-neutral-400 text-neutral-700 mt-2 flex items-center">
                            {t('countInfo.firstPart')}{' '}
                            <b className="mx-1">
                                {formatNumber(numOfCompleted, i18n.language)}
                            </b>{' '}
                            {t('countInfo.secondPart')}
                        </h1>
                    </>
                )}

                <span className="my-2 font-bold block text-neutral-800 dark:text-gray-400">
                    <span className="text-herkul-700 dark:text-herkul-400">
                        {formatNumber(counter, i18n.language)}
                    </span>{' '}
                    / {formatNumber(circle.goal, i18n.language)}
                </span>

                <span className="mt-2 mb-0.5 block text-herkul-700 dark:text-herkul-400 text-sm"></span>

                <span className="block dark:bg-neutral-700 bg-neutral-300 w-full rounded-full mb-1 relative">
                    <span className="text-herkul-700 dark:text-herkul-400 font-semibold"></span>
                    <span
                        className="block dark:bg-herkul-400 bg-herkul-700 w-full rounded-full text-right px-2 font-bold text-[14px] text-white py-1"
                        style={{
                            minWidth: '50px',
                            width: `${progressBar}%`,
                        }}
                    >
                        {percentage.toFixed(1)}%
                    </span>
                </span>

                {circle?.description && (
                    <h3 className="text-slate-700 dark:text-gray-300 whitespace-pre-wrap mt-4">
                        {i18n.language === 'tr'
                            ? sanitize(circle.description)
                            : sanitize(
                                  circle.enDescription
                                      ? circle.enDescription
                                      : circle.description
                              )}
                    </h3>
                )}

                {circle?.arabicText && circle?.turkishText && (
                    <div className="flex mt-4">
                        <button
                            onClick={() => onTabChange(0)}
                            className={`flex-1 text-sm py-3 rounded-tl-lg border-b-2 ${
                                tab === 0
                                    ? 'text-herkul-700 border-herkul-700 dark:text-herkul-300 dark:border-herkul-300'
                                    : 'text-gray-700 border-gray-300 dark:text-gray-300 dark:border-gray-600'
                            }`}
                        >
                            {t('arabicVersion')}
                        </button>
                        <button
                            onClick={() => onTabChange(1)}
                            className={`flex-1 text-sm py-3 rounded-tr-lg border-b-2 ${
                                tab === 1
                                    ? 'text-herkul-700 border-herkul-700 dark:text-herkul-300 dark:border-herkul-300'
                                    : 'text-gray-700 border-gray-300 dark:text-gray-300 dark:border-gray-600'
                            }`}
                        >
                            {t('translation')}
                        </button>
                    </div>
                )}

                {circle?.arabicText && tab === 0 && (
                    <>
                        <h2 className="text-lg text-center font-bold mb-2 mt-4 dark:text-gray-200">
                            {t('arabicVersion')}
                        </h2>
                        <p className="arabic-text text-center text-slate-600 text-3xl mt-2 mb-4 dark:text-gray-300 whitespace-pre-wrap">
                            {sanitize(circle.arabicText)}
                        </p>
                    </>
                )}

                {(((circle?.turkishText || circle.enTranslation) &&
                    tab === 1) ||
                    (circle?.turkishText && !circle?.arabicText)) && (
                    <div className="center">
                        <h2 className="text-lg text-center font-bold mt-4 dark:text-gray-200">
                            {t('translation')}
                        </h2>
                        <p
                            className="text-slate-600 text-center mt-2 dark:text-gray-300 whitespace-pre-wrap"
                            dangerouslySetInnerHTML={{
                                __html:
                                    i18n.language === 'tr'
                                        ? sanitize(circle.turkishText)
                                        : sanitize(
                                              circle.enTranslation
                                                  ? circle.enTranslation
                                                  : circle.turkishText
                                          ),
                            }}
                        ></p>
                    </div>
                )}

                <Counter
                    incrementCount={incrementCount}
                    localCounter={localCounter}
                    onClearCounter={onClearCounter}
                />
            </div>
        </>
    )
}

export default CircleDetail
