import { useTranslation } from 'react-i18next'

function AboutUs() {
    const { t, i18n } = useTranslation()

    return (
        <>
            <h2 className="text-2xl font-bold mb-2 mt-2 dark:text-gray-300">
                {t('aboutUs')}
            </h2>
            <div className="text-center dark:bg-gray-700 bg-white p-3 rounded shadow">
                <p className="mb-4 text-sm text-gray-700 dark:text-gray-200">
                    {t('developedBy')}:{' '}
                    <a
                        className="underline"
                        href="mailto:developer@hamertech.io"
                        data-splitbee-event="Email Link | About Us"
                        data-splitbee-event-destination="developer@hamertech.io"
                    >
                        developer@hamertech.io
                    </a>
                </p>

                <img
                    src="/assets/img/socal-logo.png"
                    alt="socal-logo"
                    className="w-48 my-4 inline-block"
                />

                <h6 className="text-gray-900 my-2 dark:text-gray-200 font-semibold">
                    {t('download')}
                </h6>
                <div className="flex items-center my-2 flex-wrap justify-center">
                    <a
                        href="https://apps.apple.com/app/id1602007975"
                        target="_blank"
                        className="mr-3 my-1 flex"
                        data-splitbee-event="App Store Links"
                        data-splitbee-event-destination="AppStore"
                    >
                        <img
                            src={`/assets/img/${
                                i18n.language === 'tr'
                                    ? 'app-store'
                                    : 'app-store-eng'
                            }.png`}
                            alt="app-store"
                            className="h-12 w-auto"
                        />
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.hammertech.appeleven"
                        target="_blank"
                        className="my-1 flex"
                        data-splitbee-event="App Store Links"
                        data-splitbee-event-destination="Google Store"
                    >
                        <img
                            src={`/assets/img/${
                                i18n.language === 'tr'
                                    ? 'google-store'
                                    : 'google-eng'
                            }.png`}
                            alt="app-store"
                            className="h-12 w-auto"
                        />
                    </a>
                </div>

                <p className="mt-2 text-xs text-gray-700 dark:text-gray-200">
                    {t('reserved')}
                </p>
                <p className="mt-2 text-xs text-gray-700 dark:text-gray-200">
                    &copy; {new Date().getFullYear()}
                </p>
            </div>
        </>
    )
}

export default AboutUs
