import { useTranslation } from 'react-i18next'
import { ArrowRight } from 'feather-icons-react'

export function TopBanner() {
    const { t } = useTranslation()

    return null;

    return (
        <a
            href='/qiyam'
            data-splitbee-event='App Internal Links'
            data-splitbee-event-destination='Kadir Gecesi'
            className='bg-herkul-500 text-center flex items-center justify-center text-sm py-1.5 font-semibold'
        >
            {t('linksForEq')}
            <ArrowRight className={'ml-1'} size={20} />
        </a>
    )
}
